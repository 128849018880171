/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:5e081a1b-96bb-4a72-90f5-1f5b153a4e11",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_SGCP3EvlS",
    "aws_user_pools_web_client_id": "5krco0ribl9etn16892upteitv",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://2ig6dnamrzfkfi4fwpsr2e2xum.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "kuenstlerkinder12aaa5252af14ec5b8d0110c801dcaca144849-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
